<template>
  <div :class="'dataGrid_'+config.name">
    
    <!-- Busqueda -->
    <div class="row mb-3">
      <div v-if="typeof($slots.default) != 'undefined'" :class="config.show_search ? 'col-sm-9' : 'col-sm-12'">
        <slot></slot>
      </div>
      <div v-if="config.show_search" :class="'col-sm-3'+(typeof($slots.default) != 'undefined' ? '' : ' offset-sm-9')">
        <div class="input-group">
          <input v-model="buscar" type="text" name="datagrid_search_data" id="datagrid_search_data" class="form-control" placeholder="Datos a buscar" autocomplete="off">
          <div class="input-group-append">
            <button class="btn btn-primary">Buscar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabla -->
    <div class="row">
      <div class="col-sm-12">
        <table :class="'datagrid-'+config.name">
          <thead>
            <tr>
              <th v-if="config.selector">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" name="select_all_items" id="select_all_items" ref="select_all_items" @click="seleccionar_todos">
                  <label for="select_all_items" class="custom-control-label"></label>
                </div>
              </th>
              <th v-for="(nombre,col,index) in config.cols" :key="index" :class="obtener_clase(col)" @click="ordenar(nombre, col)">{{ !nombre.text ? nombre : nombre.text }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="data_paginada.length == 0">
              <td :colspan="config.cols.length">No hay registros</td>
            </tr>
            <template v-else>
              <tr v-for="(row, row_index) in data_paginada" :key="row_index">
                <td v-if="config.selector">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input row_select_switch" :name="'select_'+row.id" :id="'select_'+row.id" :ref="'select_'+row.id" :value="row_index" @click="seleccionar_row($event, row)">
                    <label :for="'select_'+row.id" class="custom-control-label"></label>
                  </div>
                </td>
                <td v-for="(nombre,col,index) in config.cols" :key="col.id" v-html="col ? mutator(row[col], col, row, index, row_index) : mutator('', col, row, index, row_index)"></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Paginador -->
    <div class="row mt-3">
      <div v-if="navegador_paginas.length > 1" class="col-sm-6">
        <nav>
          <ul class="pagination">
            <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_anterior">Anterior</a></li>
            <template v-for="num_pagina in navegador_paginas">
            <li :class="'page-item'+(num_pagina == pagina_actual ? ' pagina_actual' : '')"><a class="page-link" href="#" @click.prevent="pagina_actual = num_pagina">{{ num_pagina }}</a></li>
            </template>
            <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_siguiente">Siguiente</a></li>
          </ul>
        </nav>
      </div>
      <div v-if="config.show_info" :class="'col-sm-6 text-right'+(navegador_paginas.length > 1 ? '' : ' offset-sm-6')">
        Total registros: {{ data_paginada.length }} | Total páginas: {{ total_paginas }} | <div style="width: 30%;display: inline-block;">
          <select v-model="por_pagina" name="por_pagina" id="por_pagina" class="form-control">
            <option value="10">10 registros por página</option>
            <option value="50">50 registros por página</option>
            <option value="100">100 registros por página</option>
            <option value="200">200 registros por página</option>
            <option value="500">500 registros por página</option>
            <option value="1000">1000 registros por página</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import BpbSwitch from './Form/Switch'

  export default {
    components: {
      BpbSwitch
    }
    ,props: {
      data: {
        type: Array
        ,default() {
          return [
            {
              columna_1: 'Columna 1'
              ,columna_2: 'Columna 2'
              ,columna_3: 'Columna 3'
              ,columna_4: 'Columna 4'
            }
            ,{
              columna_1: 'Columna 1'
              ,columna_2: 'Columna 2'
              ,columna_3: 'Columna 3'
              ,columna_4: 'Columna 4'
            }
            ,{
              columna_1: 'Columna 1'
              ,columna_2: 'Columna 2'
              ,columna_3: 'Columna 3'
              ,columna_4: 'Columna 4'
            }
            ,{
              columna_1: 'Columna 1'
              ,columna_2: 'Columna 2'
              ,columna_3: 'Columna 3'
              ,columna_4: 'Columna 4'
            }
            ,{
              columna_1: 'Columna 1'
              ,columna_2: 'Columna 2'
              ,columna_3: 'Columna 3'
              ,columna_4: 'Columna 4'
            }
          ]
        }
      }
      ,configuracion: {
        type: Object
        ,default() {
          return {}
        }
      }
      ,seleccionar: {
        type: Boolean
        ,default: false
      }
    }
    ,data() {
      return {
        pagina_actual: 1
        ,por_pagina: 10
        ,paginas_mostrar: 3
        ,buscar: null
        ,seleccionadas: []
        ,ordenar_col: 'id'
        ,ordenar_dir: 'asc'
        ,cargado: false
        ,tmp_data: []
        ,grid_nombre: null
      }
    }
    ,updated() {
      this.$emit('ready');
    }
    ,methods: {
      mutator(valor, col, row, index, row_index) {
        let mutators = this.config.mutators;

        if (typeof mutators[col] != 'function')
          return valor;

        return mutators[col](valor, row, this, index, row_index);
      }
      ,pagina_anterior() {
        if (this.pagina_actual > 1)
          this.pagina_actual -= 1;
      }
      ,pagina_siguiente() {
        if (this.pagina_actual < this.total_paginas)
          this.pagina_actual += 1;
      }
      ,seleccionar_todos(e) {
        this.data_paginada.forEach(row => {
          let name_ref = 'select_'+row.id;

          if (e.target.checked) {
            if (!this.$refs[name_ref][0].checked) {
              this.$refs[name_ref][0].click();
            }
          }else {
            if (this.$refs[name_ref][0].checked) {
              this.$refs[name_ref][0].click();
            }
          }
        })
      }
      ,seleccionar_row(e, row) {
        if (e.target.checked)
          this.seleccionadas.push(row);
        else {
          let seleccionadas = [];

          let index = 0;
          this.seleccionadas.forEach(row_sel => {
            if (row_sel.id) {
              if (row_sel.id != row.id)
                seleccionadas.push(row_sel);
            }else {
              if (index != e.target.value)
                seleccionadas.push(row_sel);
            }
          })

          this.seleccionadas = seleccionadas;
        }

        this.$emit('seleccionadas',this.seleccionadas);
      }
      ,ordenar(nombre, col) {
        let ordeable = true;

        if (typeof nombre.ordeable == 'boolean')
          ordeable = nombre.ordeable;

        if (ordeable) {
          if (typeof col == 'string') {
            if (this.config.ordenar_col == col){
              this.config.ordenar_dir = this.config.ordenar_dir == 'asc' ? 'desc' : 'asc'; 
            }else {
              this.config.ordenar_col = col;
              this.config.ordenar_dir = 'asc'
            }
          }else {
            this.config.ordenar_col = col.key;
            this.config.ordenar_dir = 'asc'
          }
        }
      }
      ,resetear(resetear_pag_actual) {
        if (typeof resetear_pag_actual == 'undefined')
          this.pagina_actual = 1;

        let datagrid = document.querySelectorAll('.dataGrid_'+this.config.name);
        let checkboxs = datagrid[0].querySelectorAll('input[type="checkbox"]');
        
        checkboxs.forEach(checkbox => {
          if (checkbox.checked && checkbox.name.indexOf('select_') != -1)
            checkbox.checked = false;
        })

        if (this.$refs.select_all_items && this.$refs.select_all_items.checked)
          this.$refs.select_all_items.checked = false;
      }
      ,obtener_clase(col) {
        if (typeof col.key == 'string') {
          if (col.key == this.ordenar_col)
            return 'row_selected';
        }else {
          if (col == this.ordenar_col)
            return 'row_selected';
        }
      }
    }
    ,computed: {
      config() {
        let config = this.configuracion;

        if (!config.cols)
          config.cols = {
            columna_1: 'Columna 1'
            ,columna_2: 'Columna 2'
            ,columna_3: 'Columna 3'
            ,columna_4: 'Columna 4'
          }

          if (config.mutators && Object.keys(config.mutators).length == 0) {
            config.mutators = {
              id(val) {
                return val;
              }
            }
          }

          if (!config.search) {
            config.search = [];

            Object.keys(config.cols).forEach(col => {
              config.search.push(col);
            })
          }

          if (typeof config.selector != 'boolean')
            config.selector = true;

          if (typeof config.show_search != 'boolean')
            config.show_search = true;

          if (typeof config.show_info != 'boolean')
            config.show_info = true;

          if (typeof config.ordenar_col == 'undefined')
            config.ordenar_col = 'id';

          if (typeof config.ordenar_col == 'undefined')
            config.ordenar_dir = 'asc';

          if (typeof config.name == 'undefined') {
            if (!this.grid_nombre) {
              this.grid_nombre = this.$helper.strRand(10);
              config.name = this.grid_nombre;
            }else {
              config.name = this.grid_nombre;
            }
          }

        return config;
      }
      ,data_filtrada() {
        if (!this.buscar)
          return this.data_ordenada;

        let data = [];
        let cols = this.config.search;

        this.data_ordenada.forEach(row => {
          if (row) {
            let encontrado = false;

            cols.forEach(col => {
              let tmp = row[col];
              tmp = this.mutator(tmp, col, row);
              
              if (tmp) {
                switch(typeof tmp) {
                  case 'string':
                  case 'array':
                    if (tmp.indexOf(this.buscar) > -1)
                      encontrado = true;
                    break;
                  case 'object':
                    let valores = Object.values(tmp);

                    valores.forEach(valor => {
                      if (!encontrado && valor.indexOf(this.buscar) > -1)
                        encontrado = true;
                    })
                    break;
                  case 'number':
                    if (tmp == this.buscar)
                      encontrado = true;
                    break;
                }
              }
            });

            if (encontrado)
              data.push(row);
          }
        })

        return data;
      }
      ,data_paginada() {
        if (this.data_filtrada.length == 0)
          return [];

        let inicio = this.pagina_actual == 1 ? 0 : ((this.pagina_actual-1)*this.por_pagina);
        let final = (this.data_filtrada.length > this.por_pagina ? (this.pagina_actual < this.total_paginas ? (inicio+this.por_pagina) : this.data_filtrada.length) : (this.data_filtrada.length))-1;

        let data = [];

        for(let i=inicio; i<=final; i++) {
          data.push(this.data_filtrada[i]);
        }

        return data;
      }
      ,data_ordenada() {
        let data = this.tmp_data;

        if (data)
          data.sort((a, b) => {
            if (this.config.ordenar_dir == 'asc') {
              if (a[this.config.ordenar_col] < b[this.config.ordenar_col])
                return -1;
              else if (a[this.config.ordenar_col] > b[this.config.ordenar_col])
                return 1;
              else
                return 0;
            }else {
              if (a[this.config.ordenar_col] < b[this.config.ordenar_col])
                return 1;
              else if (a[this.config.ordenar_col] > b[this.config.ordenar_col])
                return -1;
              else
                return 0;
            }
          })

        return data;
      }
      ,total_paginas() {
        return Math.ceil(this.data_filtrada.length / this.por_pagina);
      }
      ,navegador_paginas() {
        let inicio = this.pagina_actual == 1 ? 1 : (this.pagina_actual > this.paginas_mostrar ? (this.pagina_actual - this.paginas_mostrar) : 1);
        let final = this.pagina_actual == this.total_paginas ? this.total_paginas : (this.pagina_actual < (this.total_paginas - this.paginas_mostrar) ? (this.pagina_actual + this.paginas_mostrar) : this.total_paginas);

        let paginas = [];

        for(let i=inicio; i<=final; i++) {
          paginas.push(i);
        }

        return paginas;
      }
    }
    ,watch: {
      data: {
        handler(val) {
          this.tmp_data = val;
        }
        ,deep: true
      }
      ,pagina_actual() {
        this.resetear(false)
      }
      ,por_pagina() {
        this.resetear();
      }
      ,buscar() {
        this.resetear();
      }
      ,ordenar_col() {
        this.resetear();
      }
      ,ordenar_dir() {
        this.resetear();
      }
      ,seleccionar(val) {
        if (!val) {
          this.data_paginada.forEach(row => {
            let name_ref = 'select_'+row.id;

            if (this.$refs[name_ref][0].checked) {
              this.$refs[name_ref][0].click();
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  table {
    width: 100% !important;

    thead {
      tr {
        background-color: #4C4C4C;
        color: #fff;

        th {
          padding: 5px 10px;
          cursor: pointer;
        }

        th.row_selected {
          color: #2CB71B;
        }
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px #4C4C4C;

        td {
          padding: 5px 10px;
        }
      }

      tr:hover {
        background-color: #FFFED2;
      }

      tr:last-child {
        border: 0px
      }
    }
  }

  .pagina_actual {
    a {
      background-color: #7BA7E7;
      // color: #fff;
    }
  }
</style>